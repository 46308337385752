export var StatusType;
(function (StatusType) {
    StatusType[StatusType["SUCCESS"] = 0] = "SUCCESS";
    StatusType[StatusType["LOADING"] = 1] = "LOADING";
    StatusType[StatusType["ERROR"] = 2] = "ERROR";
})(StatusType || (StatusType = {}));
export var AuthActionKeyType;
(function (AuthActionKeyType) {
    AuthActionKeyType[AuthActionKeyType["LOGIN"] = 0] = "LOGIN";
    AuthActionKeyType[AuthActionKeyType["USER_DATA_LOADING"] = 1] = "USER_DATA_LOADING";
    AuthActionKeyType[AuthActionKeyType["USER_DATA_SUCCESS"] = 2] = "USER_DATA_SUCCESS";
    AuthActionKeyType[AuthActionKeyType["USER_DATA_ERROR"] = 3] = "USER_DATA_ERROR";
    AuthActionKeyType[AuthActionKeyType["USER_CLAIMS_LOADING"] = 4] = "USER_CLAIMS_LOADING";
    AuthActionKeyType[AuthActionKeyType["USER_CLAIMS_SUCCESS"] = 5] = "USER_CLAIMS_SUCCESS";
    AuthActionKeyType[AuthActionKeyType["USER_CLAIMS_ERROR"] = 6] = "USER_CLAIMS_ERROR";
    AuthActionKeyType[AuthActionKeyType["LOGOUT"] = 7] = "LOGOUT";
})(AuthActionKeyType || (AuthActionKeyType = {}));
export var initialAuthState = {
    authenticated: false,
    user: null,
    userData: null,
    userClaims: null
};
var authReducer = function authReducer(state, action) {
    switch (action.type) {
        case AuthActionKeyType.LOGIN:
            return {
                authenticated: true,
                user: action.payload.user,
                userData: {
                    status: StatusType.LOADING
                },
                userClaims: {
                    status: StatusType.LOADING
                }
            };
        case AuthActionKeyType.USER_DATA_LOADING:
            if (state.authenticated) {
                return Object.assign({}, state, {
                    userData: {
                        status: StatusType.LOADING
                    }
                });
            }
            return state;
        case AuthActionKeyType.USER_DATA_SUCCESS:
            if (state.authenticated) {
                return Object.assign({}, state, {
                    userData: {
                        status: StatusType.SUCCESS,
                        data: action.payload.data
                    }
                });
            }
            return state;
        case AuthActionKeyType.USER_DATA_ERROR:
            if (state.authenticated) {
                return Object.assign({}, state, {
                    userData: {
                        status: StatusType.ERROR,
                        error: action.payload.error
                    }
                });
            }
            return state;
        case AuthActionKeyType.USER_CLAIMS_LOADING:
            if (state.authenticated) {
                return Object.assign({}, state, {
                    userClaims: {
                        status: StatusType.LOADING
                    }
                });
            }
            return state;
        case AuthActionKeyType.USER_CLAIMS_SUCCESS:
            if (state.authenticated) {
                return Object.assign({}, state, {
                    userClaims: {
                        status: StatusType.SUCCESS,
                        data: action.payload.data
                    }
                });
            }
            return state;
        case AuthActionKeyType.USER_CLAIMS_ERROR:
            if (state.authenticated) {
                return Object.assign({}, state, {
                    userClaims: {
                        status: StatusType.ERROR,
                        error: action.payload.error
                    }
                });
            }
            return state;
        case AuthActionKeyType.LOGOUT:
            return {
                authenticated: false,
                user: null,
                userData: null,
                userClaims: null
            };
        default:
            return state;
    }
};
export default authReducer;
