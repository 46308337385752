module.exports = {
  theme: {
    extend: {
      colors: {
        makroOrange: "#ff5b00",
        makroOrangeLight: "#ffe8da",
        makroGreen: "#005C16",
        makroGreenLight: "#D9F3F0",
        makroBlue: "#0066ba",
        makroBlueLight: "#e2f1fe",
        makroRusty: "#cf4f54",
        makroRustyLight: "#ffdfdf",
        makroRedPortuguese: "#FF0015",
        makroYellow: "#fec859",
        makroPurple: "#491d88",
        // Helper colors
        makroGreyDark: "#323232",
        makroGreyLight: "#f2f2f2",
        // Modal colors
        makroBlackLight: "#151515bf",
        errorMessage: "#f56565"
      }
    }
  },
  variants: {},
  plugins: [],
  purge: false,
  future: {
    purgeLayersByDefault: true,
    removeDeprecatedGapUtilities: true
  }
}
