var token = "e86605afcc1c9e04add74241869e5b";
export var datoCmsId = function datoCmsId(fullId) {
    return removeAfterLastOccurrence(removeBeforeCharacter(fullId, "-"), "-");
};
export var gatsbyDatoCmsId = function gatsbyDatoCmsId(id, modelName) {
    return "DatoCms" + modelName + "-" + id + "-de";
};
export var bookmarkedArticleQuery = function bookmarkedArticleQuery(bookmarks) {
    return "{\n     allArticles(\n        filter: { id: { in: " + JSON.stringify(bookmarks) + " } }\n    ) {\n      excerpt\n      id\n      title\n      smallTitle\n      slug\n      authors {\n        name\n      }\n      image {\n        responsiveImage {\n          base64\n          aspectRatio\n          width\n          height\n          src\n          srcSet\n          sizes\n        }\n      }\n     }\n   }";
};
export var fetchFromDatoCms = function fetchFromDatoCms(query) {
    return fetch("https://graphql.datocms.com", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token
        },
        body: JSON.stringify({
            query: query
        })
    });
};
function removeBeforeCharacter(str, char) {
    var index = str.indexOf(char);
    if (index !== -1) {
        return str.substring(index + 1);
    }
    return str;
}
function removeAfterLastOccurrence(str, char) {
    var lastIndex = str.lastIndexOf(char);
    if (lastIndex !== -1) {
        return str.substring(0, lastIndex);
    }
    else {
        return str;
    }
}
